@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* font-family: 'Montserrat', sans-serif; */

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Montserrat, sans-serif;
}
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--blue);
}
a,
a:focus,
a:hover {
    text-decoration: none;
}
body {
    background: #fff;
}
:root {
    --blue: #00104e;
    --light-blue: #e7edf5;
}
html {
    height: 100%;
}
body {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 16, 78, 0.5);
    margin: 0;
    padding: 0;
    height: 100%;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ol,
ul {
    list-style: none;
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    margin-top: 0;
}
img {
    max-width: 100%;
}
a:focus,
a:hover {
    outline: 0;
    color: #485e75;
}
p {
    font-size: 16px;
    line-height: 24px;
    color: #1d1d1d;
}
.d-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.container {
    max-width: 1475px;
    padding: 0 15px;
    margin: 0 auto;
}
.pagebox {
    padding: 25px 15px 0;
    background: #fff;
}
.wrapper_bg {
    position: relative;
    overflow: hidden;
}
.wrapper_bg::before {
    background-image: url(../images/Isolation_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    left: 250px;
    top: 80px;
    width: 864px;
    height: 832px;
    position: absolute;
    opacity: 0.1;
    pointer-events: none;
    z-index: 1;
}
.wrapper_bg * {
    position: relative;
    /* Z Index Commented Because Datepicker not show Properly */
    /* z-index: 2; */
}

/* Header css starts */

.header {
    position: relative;
    background: var(--light-blue);
    border-radius: 30px;
    margin: 0 auto 16px;
    padding: 10px 24px;
    max-width: 1493px;
    z-index: 99;
}
.header .logo {
    display: inline-block;
}
.header .logo > img {
    vertical-align: top;
    height: 42px;
    object-fit: contain;
}
.header .actions {
    align-items: center;
    gap: 16px;
    flex-wrap: nowrap;
}
.header .headerInner {
    justify-content: space-between;
    align-items: center;
}
.header .btn-site {
    min-width: 120px;
}

/* Content css starts */

.contactSection,
.optionsBelt {
    margin: 0 -15px;
    border-bottom: 1px solid var(--blue);
}
.btn-site,
.contactSection .contactBox,
.optionsBelt,
.selectList li,
.text-center,
div.btn-container {
    text-align: center;
}
.btn-site,
.selectList li,
.site-title {
    text-transform: uppercase;
}
.optionsBelt {
    justify-content: space-between;
    padding-bottom: 10px;
}
.optionsBelt .option {
    margin-bottom: 10px;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 15px;
}
.optionsBelt .option a {
    display: inline-flex;
    flex-direction: column;
}
.contactBox .conContent .icon,
.optionsBelt .option .icon {
    margin-bottom: 10px;
}
.optionsBelt .option .icon svg {
    height: 48px;
    object-fit: contain;
    object-position: center;
    vertical-align: top;
}
.optionsBelt .option h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.contactSection {
    padding: 40px 0 15px;
    position: relative;
}
.contactSection .contactBox {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 25px;
}
.contactSection .contactBox .col {
    padding: 0 25px;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 25px;
}
.contactSection .contactBox .conContent {
    border-radius: 12px;
    padding: 15px;
    border: 1px solid var(--blue);
    background: 0 0;
    overflow: hidden;
    height: 100%;
}
.contactBox .conContent .icon svg {
    vertical-align: top;
    height: 50px;
    object-fit: contain;
    object-position: center;
}
.contactBox .conContent h3 {
    color: var(--blue);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.formfillSection {
    padding: 40px 0 0;
}
.formfillSection .site-title {
    margin-bottom: 45px;
}
.formfillSection .container {
    max-width: 1400px;
}
.formRow {
    margin: 0 -35px;
}
.formRow .formCol {
    padding: 0 35px 40px;
    margin-bottom: 0;
    border-left: 1px solid rgba(0, 16, 78, 0.3);
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
.formRow .formCol:first-child {
    border-left: 0;
}
.formRow .formCol h4 {
    margin-bottom: 30px;
    color: var(--blue);
    font-size: 18px;
    font-weight: 500;
}
.formCol .btn-site {
    height: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
}
.formCol .btn-site i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #fff;
}
.selectList {
    position: relative;
    margin-bottom: 70px;
}
.selectList li {
    margin-bottom: 24px;
    display: block;
    padding: 25px 10px;
    font-size: 16px;
    font-weight: 600;
    background: var(--light-blue);
    border: 1px solid var(--blue);
    overflow: hidden;
    border-radius: 15px;
    cursor: pointer;
}
.selectList li.selected,
.selectList li:hover {
    background: var(--blue);
    color: #fff;
}
.form-control {
    border-radius: 8px;
    border: 1px solid rgba(0, 16, 78, 0.5);
    height: 34px;
    color: #111;
    display: block;
    width: 100%;
    padding: 5px 12px;
    outline: 0;
    box-shadow: none;
    font-size: 14px;
}
.form-control:focus {
    border-color: var(--blue);
}
.selfDetails {
    border-radius: 12px;
    border: 2px solid rgba(0, 16, 78, 0.1);
    background: rgba(0, 16, 78, 0.02);
    padding: 15px;
    /* DatePicker Not show  */
    /* overflow: hidden; */
    margin-bottom: 30px;
}
.selfDetails h5 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
    color: var(--blue);
}
.selfDetails .form-item {
    margin-bottom: 15px;
    gap: 5px;
    align-items: center;
}
.selfDetails .form-item label {
    white-space: wrap;
    font-size: 14px;
    color: var(--blue);
    font-weight: 600;
}
.form-item .control {
    margin-left: auto;
}
div.btn-container {
    display: table-cell;
    vertical-align: middle;
}
div.btn-container i {
    display: inline-block;
    position: relative;
    top: -9px;
}
.btn-color-mode-switch {
    display: inline-block;
    margin: 0;
    position: relative;
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0;
    width: 140px;
    height: 40px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    display: block;
    border-radius: 8px;
    border: 1px solid rgba(0, 16, 78, 0.2);
    background: rgba(0, 16, 78, 0.1);
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 50%;
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 70px;
    height: 38px;
    background: var(--blue);
    border-radius: 8px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.btn-color-mode-switch > .alert {
    display: none;
    background: #ff9800;
    border: none;
    color: #fff;
}
.btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0;
}
.btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner {
    background: rgba(0, 16, 78, 0.1);
    color: var(--blue);
}
.btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 68px;
    background: var(--blue);
}
.btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
    display: block;
}

.mb-0 {
    margin-bottom: 0 !important;
}
.form-group,
.home_bg {
    position: relative;
}
.innerWrapper {
    padding-top: 30px;
}
.titleSection {
    margin-bottom: 25px;
}
.titleSection p {
    font-size: 16px;
    font-weight: 600;
    max-width: 340px;
    margin: 0 auto 15px;
    color: #00104e;
}
.titleSection .site-title {
    margin-bottom: 15px;
}
.titleSection .subtext {
    font-size: 11px;
    color: #00104e;
}
.membershipContainer {
    max-width: 740px;
    margin: 0 auto 40px;
    width: 100%;
}
.membershipformRow {
    margin: 0 -20px;
}
.membershipformRow .formCol {
    padding: 0 20px;
    margin-bottom: 30px;
    flex: 0 0 50%;
    max-width: 50%;
}
.bannerSection .site-logo,
.bannerSection h2,
.bannerSection h3,
.form-group {
    margin-bottom: 20px;
}
.formCol .box {
    overflow: hidden;
    padding: 24px;
    border-radius: 24px;
    border: 1px solid var(--blue);
    background: #fff;
}
.membershipformRow form label {
    display: block;
    font-size: 12px;
    color: var(--blue);
    margin-bottom: 8px;
}
.membershipformRow form .form-group:last-child {
    margin-bottom: 0;
}
.membershipformRow .form-control {
    border-radius: 8px;
    border: 1px solid rgba(0, 16, 78, 0.2);
    background: rgba(0, 16, 78, 0.05);
}
.membershipContainer .btn-site {
    border-radius: 15px;
    padding: 15px 40px;
}
.home_bg::before {
    position: absolute;
    content: "";
    background-image: url("../images/homebanner.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: transparent !important;
    width: 100%;
    height: 85%;
    bottom: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    background-size: contain;
}
.home_bg .pagebox {
    background: 0 0;
}
.home_bg .wrapper_bg {
    /* min-height: 760px; */
    min-height: 86vh;
}
.home_bg .wrapper_bg::before {
    background-image: url("../images/Isolation_gray_bg.png");
    z-index: 1;
}
.bannerSection {
    width: 100%;
    max-width: 620px;
    padding-top: 200px;
    z-index: 2;
}
.bannerSection .site-logo img {
    width: 100%;
    max-width: 350px;
}
.bannerSection h2 {
    font-size: 36px;
    font-weight: 600;
}
.bannerSection h3 {
    font-size: 30px;
    font-weight: 400;
}
.bannerSection .btn-site {
    font-weight: 700;
    min-width: 200px;
}
/* ADD PLAN ROW CSS */

.planROw {
    position: relative;
}
.planROw .col {
    flex: auto;
}
.planROw .col.formCol {
    border-right: 1px solid rgba(0, 16, 78, 0.3);
    flex: 0 0 450px;
    max-width: 450px;
}
.planROw .col {
    margin-bottom: 40px;
}
.planROw .covered_plan {
    border-radius: 15px;
    background: #e7edf5;
    padding: 30px;
    overflow: hidden;
    width: calc(100% - 20px);
}
.planROw .selectList {
    max-width: 215px;
    margin: 0px auto;
    margin-bottom: 30px;
}
.planROw .selectList li {
    margin-bottom: 16px;
    padding: 16px 10px;
    background: #ffffff;
    color: var(--blue);
}
.planROw .selectList li:hover,
.planROw .selectList li.active {
    background: var(--blue);
    color: #ffffff;
}
.planROw .planRollbox {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    position: relative;
}
.planROw .planRollbox::before {
    position: absolute;
    top: 0px;
    width: 397px;
    height: 26px;
    content: "";
    background-image: url(../images/plzn-roll.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    z-index: 1;
}
.planROw .planRollbox::after {
    position: absolute;
    top: 11px;
    width: 360px;
    height: 466px;
    content: "";
    background-image: url(../images/rollBg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    z-index: 2;
    pointer-events: none;
}
.planROw .planRollbox {
    min-height: 466px;
    padding: 30px 30px 20px;
    margin-top: 15px;
    margin-bottom: 25px;
}
.planROw .planRollbox * {
    position: relative;
    z-index: 3;
}
.planRollbox .icon {
    width: 45px;
    margin: 0px auto;
    margin-bottom: 15px;
}
.planRollbox .icon img {
    width: 100%;
}
.planRollbox .price {
    font-size: 24px;
    padding: 10px 5px;
    font-weight: 300;
    border-bottom: 1px dashed var(--blue);
    border-top: 1px dashed var(--blue);
    margin-bottom: 20px;
}
.planRollbox .price * {
    font-size: 24px;
}
.planRollbox .content {
    padding: 0px 30px;
}
.planRollbox ul {
    border-bottom: 1px dashed var(--blue);
    padding-bottom: 12px;
    margin-bottom: 12px;
}
.planRollbox ul li {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    font-weight: 300;
    color: var(--blue);
    font-size: 14px;
    margin-bottom: 8px;
}
.planRollbox ul li:last-child {
    margin-bottom: 0px;
}
.planRollbox * {
    font-size: 13px;
    color: var(--blue);
}
.contact_dt {
    padding-left: 20px;
}
.contact_dt p {
    text-align: left;
    margin-bottom: 8px;
    color: var(--blue);
    margin-top: 0px;
}
.btn-row {
    justify-content: center;
    gap: 12px;
}
.btn-row .btn {
    border-radius: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 215px;
    height: 52px;
}
.accordianSection {
    position: relative;
    padding-top: 80px;
}
.accordianSection .accordion-list {
    max-width: 1034px;
    width: 100%;
    margin: 0px auto;
}
ul.accordion-list {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0px;
    margin: 0;
    list-style: none;
    background-color: #f9f9fa;
}
ul.accordion-list > li {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 10px 0px;
    margin: 0 auto 0px auto;
    border-bottom: 1px solid var(--blue);
    border-radius: 0px;
    cursor: pointer;
}
ul.accordion-list > li.active h3:after {
    transform: rotate(180deg);
}
ul.accordion-list > li h3 {
    font-weight: 400;
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0 0 0;
    margin: 0;
    font-size: 20px;
    letter-spacing: 0.6px;
    cursor: pointer;
    padding-right: 20px;
}
ul.accordion-list > li h3:after {
    content: "\f106";
    font-family: "Font Awesome 6 Pro";
    position: absolute;
    right: 0;
    top: 3px;
    color: var(--blue);
    transition: all 0.3s ease-in-out;
    font-size: 18px;
}

ul.accordion-list > li div.answer {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 15px 0px;
    cursor: pointer;
}
.accordion-list .answer p {
    color: var(--blue);
    font-size: 12px;
    font-weight: 400;
}
.answer ul li {
    color: var(--blue);
}
ul.accordion-list > li .answer h3 {
    font-size: 14px;
    font-weight: 400;
    color: var(--blue);
    margin-bottom: 15px;
}
.tablecontent {
    margin-bottom: 25px;
}
.tablecontent h4 {
    font-size: 13px;
    font-weight: 600;
    color: var(--blue);
    margin-bottom: 20px;
}
.tablecontent .table-list {
    width: 100%;
}
.tablecontent .table-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 2px;
}
ul.dashed {
    list-style-type: none;
}
ul.dashed > li {
    text-indent: -5px;
}
ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
}
.answer p b {
    font-weight: 500;
}
.btn-site-success {
    border: 1px solid #0b3604 !important;
    background: #def9dc !important;
    color: #0b3604 !important;
}
.btn-site-success:hover,
.btn-site-success:focus,
.btn-site-success:active {
    background: #0b3604 !important;
    color: #ffffff !important;
}

.row {
    margin: 0px -15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.row .col {
    padding: 0px 15px;
}
/* ADD PLAN ROW CSS */
.site-title {
    color: var(--blue);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}
.nowrap {
    flex-wrap: nowrap;
}
.font-normal {
    font-weight: 400 !important;
}
.btn-site {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    border-radius: 30px;
    background: var(--blue);
    border: 1px solid var(--blue);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.btn-site:active,
.btn-site:focus,
.btn-site:hover {
    background: var(--light-blue);
    color: var(--blue);
}
.btn-site:active i,
.btn-site:focus i,
.btn-site:hover i {
    color: var(--blue);
}
.btn-site.btn-reverse {
    background: var(--light-blue);
    border: 1px solid var(--blue);
    color: var(--blue);
}
.btn-site.btn-reverse:active,
.btn-site.btn-reverse:focus,
.btn-site.btn-reverse:hover {
    background: var(--blue);
    border: 1px solid var(--blue);
    color: var(--light-blue);
}
.btn-success {
    border: 1px solid #0b3604;
    background: #def9dc;
    color: #0b3604;
}
.btn-success:active,
.btn-success:focus,
.btn-success:hover {
    background: #0b3604;
    color: #def9dc;
}

/* Common css */
#logo span,
.btn-site,
.selectList li,
a,
a *,
button {
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    transition: 0.3s linear;
}
.d-none {
    display: none;
}
.d-block {
    display: block;
}
.d-flex {
    display: flex !important;
}
::-webkit-input-placeholder {
    font-family: Montserrat, sans-serif;
    color: rgba(0, 16, 78, 0.5);
}
::-moz-placeholder {
    font-family: Montserrat, sans-serif;
    color: rgba(0, 16, 78, 0.5);
}
:-ms-input-placeholder {
    font-family: Montserrat, sans-serif;
    color: rgba(0, 16, 78, 0.5);
}
:-moz-placeholder {
    font-family: Montserrat, sans-serif;
    color: rgba(0, 16, 78, 0.5);
}

/* Media query */

@media only screen and (max-width: 1750px) {
    .home_bg::before {
        width: 100%;
        height: 70%;
    }
}

@media only screen and (max-width: 1440px) {
    .formRow {
        margin: 0 -15px;
    }
    .formRow .formCol {
        padding: 0 15px;
    }
    .selfDetails .form-item label {
        white-space: wrap;
    }
    .wrapper_bg::before {
        left: 180px;
        top: 60px;
        width: 600px;
        height: 600px;
    }
    .bannerSection {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 1200px) {
    .pagebox {
        padding: 15px 15px 0;
    }
    .contactBox .conContent h3 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 991px) {
    .header {
        padding: 6px 12px;
    }
    .header .actions {
        gap: 8px;
    }
    .header .btn-site {
        min-width: initial;
    }
    .wrapper_bg::before {
        left: 100px;
        top: 80px;
        width: 500px;
        height: 500px;
    }
    .btn-site {
        padding: 8px 12px;
    }
    .contactSection .contactBox,
    .contactSection .contactBox .col {
        padding: 0 15px;
    }
    .selectList li {
        margin-bottom: 15px;
        display: block;
        padding: 16px 8px;
        text-align: center;
        font-size: 14px;
    }
    .formRow .formCol {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .formRow .formCol:last-child {
        flex: 0 0 100%;
        max-width: 100%;
        border-left: 0;
    }

    .bannerSection {
        padding-top: 0px;
    }
    .planROw {
        justify-content: center;
    }
    .planROw .col {
        flex: 0 0 100% !important;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .planROw .col.formCol {
        border-right: 0px;
    }
    .planROw .covered_plan {
        width: 100%;
    }
    .accordianSection {
        padding-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .header .logo > img {
        max-width: 100px;
    }
    .header .btn-site > i {
        display: inline-block;
    }
    .header .btn-site span {
        text-indent: -9999px;
    }
    .wrapper_bg::before {
        left: 0;
        top: 80px;
        width: 500px;
        height: 500px;
    }
    .optionsBelt {
        margin: 0 -10px;
    }
    .optionsBelt .option {
        padding: 0 10px;
    }
    .optionsBelt .option .icon svg {
        height: 35px;
    }
    .optionsBelt .option h2 {
        font-size: 14px;
    }

    .membershipformRow .formCol {
        margin-bottom: 30px;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .titleSection p {
        font-size: 14px;
        max-width: 100%;
    }
    .form-group,
    .membershipContainer {
        margin-bottom: 15px;
    }
    .formCol .box {
        padding: 16px;
        border-radius: 16px;
    }
    .bannerSection .site-logo img {
        max-width: 200px;
    }
    .bannerSection h2 {
        font-size: 26px;
    }
    .bannerSection h3 {
        font-size: 20px;
    }
    ul.accordion-list > li h3 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 640px) {
    .header .btn-site {
        padding: 8px 10px;
        font-size: 10px;
    }
    .header .actions .refresh-icon > img {
        max-width: 22px;
    }
    .header .actions {
        gap: 5px;
    }
    .header .logo > img {
        max-width: 85px;
    }
    .contactSection .contactBox {
        padding: 0;
    }
    .contactSection .contactBox .col {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .site-title {
        font-size: 20px;
    }
    .formRow .formCol {
        flex: 0 0 100%;
        max-width: 100%;
        border: 0;
    }
    .selectList {
        margin-bottom: 30px;
    }
    .formfillSection .site-title {
        margin-bottom: 25px;
    }
    .selectList li {
        margin-bottom: 8px;
        padding: 14px 8px;
    }
    .formRow .formCol:last-child {
        margin-bottom: 0;
    }
    .planROw .covered_plan {
        padding: 20px;
    }
    .planROw .planRollbox::after {
        width: 100%;
        display: none;
    }
    .planROw .planRollbox {
        padding: 30px 10px 0px;
        min-height: initial;
        margin-bottom: 10px;
    }
    .planROw .planRollbox::before {
        width: 100%;
    }
    .planRollbox .content {
        border: 1px solid var(--blue);
        top: -20px;
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
/* Custom Datepicker Css */
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker {
    font-size: 14px; /* Adjust the font size as needed */
}

.react-datepicker__input-container {
    width: 100%;
}

/* Style the DatePicker input field */
.react-datepicker__input-container input {
    width: 100%;
    padding: 10px; /* Adjust padding as needed */
}

/* Style the calendar container */
.react-datepicker__portal {
    position: fixed;
    z-index: 9999; /* Ensure it appears above other elements */
}

/* Additional styling for smaller screens */
@media (max-width: 768px) {
    .react-datepicker {
        font-size: 12px; /* Adjust font size for smaller screens */
    }
}

/* Custom Datepicker Css */
.container-title {
    display: flex;
}
.icon-container {
    margin-left: auto; /* Push the icon to the right */
}
.marginRight {
    margin-right: 10px;
}
.buttonFullWidth {
    width: 100%;
}

.selected-option {
    color: var(--blue);
}
.unSelected-option {
    color: #ccc;
}
.errorText {
    color: red !important;
}
.dateInputError {
    border: 1px solid red !important;
}
.iconColor {
    color: var(--blue);
}
.marginBottom {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .show-icon {
        display: inline;
    }
}

@media (min-width: 768px) {
    .show-icon {
        display: none !important;
    }
}

.thankYouWrapper {
    border-radius: 30px;
    border: 1px solid #00104e;
    background: #e7edf5;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 60vh;
    padding: 10%;
}

.thankyouChildren {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.thankyouContainer {
    display: flex;
    justify-content: center;
}
.thankyouTitle {
    margin-top: 10px;
}
